import React, { useState, useCallback } from "react";
import { fromNow } from "../helpers/dates";
import { PLACEHOLDER, BANNER } from "../helpers/constant";
import { useHistory } from "react-router";
import SquareImage from "../component/Image/SquareImage";
import RoundImage from "../component/Image/RoundImage";
import Button from "../component/Button/Button";
import CoverImage from "../component/Image/CoverImage";
import { useParams } from "react-router-dom";
import { challenges as VoteChallenge } from "../api/challenges";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import styles from "../pages/Home/home.module.css";

const ChallengesWidget = React.memo((props) => {
    const challenges = props.challenges;

    const renderList = () => {
        return challenges?.map((c) => {
            return (
                <li key={c.id}>
                    <SquareImage className="img-thumbnail" alt={"Challenge Banner"} src={c.thumbnail || PLACEHOLDER} height={"50px"} width={"50px"} />
                    <div className="re-links-meta">
                        <h6 className="text-truncate">
                            <Link to={"challenge/" + c.id.toString() + "/accept"}>{c.title}</Link>
                            {/* <a title={c.title} href={'challenge/' + c.id.toString() + '/accept'}>{c.title}</a> */}
                        </h6>
                        <span>{fromNow(c.created_at)}</span>
                    </div>
                </li>
            );
        });
    };

    return (
        <aside className="sidebar static right">
            <div className="widget pb-2 mb-3">
                <h4 className="widget-title">
                    <i className="icofont-trophy text-warning mr-2"></i>Challenges{" "}
                    <Link to={`all/challenges`} className="see-all">
                        See All
                    </Link>
                </h4>
                <ul className={`${styles["recent-links"]} recent-links`}>{renderList()}</ul>
            </div>
        </aside>
    );
});

export const ChallengersWidget = (props) => {
    const challenger = props.post;
    const votable = props.votable;
    const history = useHistory();

    const [hasVoted, setHasVoted] = useState(challenger.has_voted);
    const dispatch = useDispatch();
    const { challengeId } = useParams();
    //vote for challenge
    const voteChallenge = useCallback((challenger_id, e) => {
        e.stopPropagation();
        dispatch(
            VoteChallenge({
                param_path: challengeId + "/challengers/" + challenger_id + "/vote",
                method: "POST",
            }),
        )
            .then((response) => {
                if (response.success) {
                    setHasVoted((p) => !p);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    });
    return (
        <>
            <div style={{height: props.height, padding: '15px', position: 'relative'}}>
                <CoverImage image bgsrc={`${challenger.thumbnail || BANNER}`} width="100%" height={props.height}>
                    {votable && props.showButton && (
                        <Button
                            style={{
                                top: "5px",
                                right: "5px",
                                zIndex: "9999",
                            }}
                            className="btn btn-info btn-sm d-inline w-auto position-absolute"
                            type="button"
                            onClick={(e) => voteChallenge(props.challenger_id, e)}
                        >
                            <i className="icofont-stamp mr-1"></i>
                            {hasVoted ? "Unvote" : "Vote"}
                        </Button>
                    )}
                    <div className="position-absolute" style={{ bottom: "15px" }}>
                        <RoundImage className="img-thumbnail" size="50px" alt={challenger.created_by.name} src={challenger.created_by.profile_pic || PLACEHOLDER} />
                    </div>
                </CoverImage>
            </div>
            {/* <CoverImage image bgsrc={`${challenger.thumbnail || BANNER}`} width="100%" height={props.height}>
                {votable && props.showButton && (
                    <Button
                        style={{
                            top: "5px",
                            right: "5px",
                            zIndex: "9999",
                        }}
                        className="btn btn-info btn-sm d-inline w-auto position-absolute"
                        type="button"
                        onClick={(e) => voteChallenge(props.challenger_id, e)}
                    >
                        <i className="icofont-stamp mr-1"></i>
                        {hasVoted ? "Unvote" : "Vote"}
                    </Button>
                )}
                <div className="position-absolute ml-1" style={{ bottom: "0" }}>
                    <RoundImage className="img-thumbnail" size="50px" alt={challenger.created_by.name} src={challenger.created_by.profile_pic || PLACEHOLDER} />
                </div>
            </CoverImage> */}
            {/* <div className="runnersup border border-grey-200">
                <div className="screen-winner">
                    <div>
                        <CoverImage className="rounded position-relative" bgrepeat="no-repeat" bgsize="cover" bgsrc={`url(${challenger.thumbnail || BANNER})`} width="100%" height={props.height}>
                            {votable && props.showButton && (
                                <Button
                                    style={{
                                        top: "5px",
                                        right: "5px",
                                        zIndex: "9999",
                                    }}
                                    className="btn btn-info btn-sm d-inline w-auto position-absolute"
                                    type="button"
                                    onClick={(e) => voteChallenge(props.challenger_id, e)}
                                >
                                    <i className="icofont-stamp mr-1"></i>
                                    {hasVoted ? "Unvote" : "Vote"}
                                </Button>
                            )}
                            <div className="position-absolute" style={{ bottom: "0" }}>
                                <RoundImage className="img-thumbnail" size="50px" alt={challenger.created_by.name} src={challenger.created_by.profile_pic || PLACEHOLDER} />
                            </div>
                        </CoverImage>
                    </div>
                </div>
            </div> */}
        </>
    );
};

export default ChallengesWidget;
