import React from "react";

const InterestCarousel = (props) => {
    const interest = props.interest;
    return (
        <div className="link-item">
            <a className={`${props.isActive ? "active" : ""}`} onClick={props.onClick}>
                <p>{interest.title}</p>
            </a>
        </div>
    );
};

export default InterestCarousel;
